export class Tete {
	id: string;
	libelle: string;

	constructor(
		libelle: string,
	) {
		this.libelle = libelle;
	}
}
