import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConduiteService} from '../../../../../core/apps/conduite.service';
import {Espece} from '../../../../../models/espece';

@Component({
  selector: 'kt-add-especes',
  templateUrl: './add-especes.component.html',
  styleUrls: ['./add-especes.component.scss']
})
export class AddEspecesComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;
	typeoperation;
	selectedValue: string ="";
	constructor(
		public dialogRef: MatDialogRef<AddEspecesComponent>,
		private formBuilder: FormBuilder,
		private TripService: ConduiteService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { }

	ngOnInit() {
		this.initForm();
	}

	onNoClick() {
		this.dialogRef.close();
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			libelle: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const libelle = this.tripForm.get("libelle").value;
		const newAgriculteur = new Espece(libelle);
		this.TripService.saveEspece(newAgriculteur).then( res => {
		});

	}
	onUpdate(id) {
		const libelle = this.tripForm.get("libelle").value;

		let a = {
			id: id,
			libelle: libelle,
		};
		this.TripService.updateEspece(a).then( res => {
		});

	}
	ondelete(id) {

		this.TripService.deleteEspece(id);
		this.dialogRef.close();

	}
}
