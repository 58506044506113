export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: "Dashboards",
					root: true,
					alignment: "left",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
				},
			],
		},
		aside: {
			self: {},
			items: [
				{
					title: "Dashboard",
					root: true,
					icon: "flaticon2-architecture-and-city",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{ section: "Cabinet" },
				{
					title: "Examen",
					root: true,
					bullet: "dot",
					icon: "flaticon2-browser-2",
					page: "examen",
				},
				{
					title: "Gestion dynamique",
					bullet: "dot",
					submenu: [
						{
							title: "Conduite à tenir",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "conduite",
						},
						{
							title: "Diagnostic",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "diagnostic",
						},
						{
							title: "Entreprises",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "entreprise",
						},
						{
							title: "Ecloseries",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "ecloseries",
						},
						{
							title: "Espèces",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "especes",
						},
						{
							title: "Incidents",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							page: "incident",
						},
						{
							title: "Recherche",
							root: true,
							bullet: "dot",
							icon: "flaticon2-browser-2",
							submenu: [
								{
									title: "Parasitaire",
									page: "parasitaire",
								},
								{
									title: "Bactériologique",
									page: "bacteriologique",
								},
							],
						},
						{
							title: "Examen externe",
							bullet: "dot",
							icon: "flaticon2-list-2",
							submenu: [
								{
									title: "Etat d’embonpoint",
									page: "embonpoint",
								},
								{
									title: "Revêtement cutané",
									page: "revetementcutane",
								},
								{
									title: "Nageoires",
									page: "nageoires",
								},
								{
									title: "Opercule",
									page: "opercule",
								},
							],
						},
						{
							title: "Examen interne",
							bullet: "dot",
							icon: "flaticon2-browser-2",
							submenu: [
								{
									title: "Bouche",
									page: "bouche",
								},
								{
									title: "Estomac",
									page: "estomac",
								},
								{
									title: "Intestin",
									page: "intestin",
								},
								{
									title: "Branchie",
									page: "branchie",
								},
								{
									title: "Cœur",
									page: "coeur",
								},
								{
									title: "Tête",
									page: "tete",
								},
								{
									title: "Yeux",
									page: "yeux",
								},
								{
									title: "Cerveau",
									page: "cerveau",
								},
								{
									title: "Rate",
									page: "rate",
								},
								{
									title: "Foie",
									page: "foie",
								},
								{
									title: "Vésicule biliaire",
									page: "vesiculebiliaire",
								},
								{
									title: "Rein",
									page: "rein",
								},
								{
									title: "Vessie natatoire",
									page: "vessienatatoire",
								},
								{
									title: "Autres",
									page: "autres",
								},
							],
						},

					],
				},
				{
					title: "Gestion statique",
					bullet: "dot",
					submenu: [
						{
							title: "Parametres",
							bullet: "dot",
							icon: "flaticon2-list-2",
							submenu: [
								{
									title: "Prévalence",
									page: "prevalence",
								},
								{
									title: "Forme",
									page: "forme",
								},
								{
									title: "Molécules",
									page: "molecules",
								},
								{
									title: "Durée du traitement",
									page: "dureetraitement",
								},
								{
									title: "Fréquence",
									page: "frequences",
								},
								{
									title: "Sévérité",
									page: "severite",
								},
							],
						},
					],
				},
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
