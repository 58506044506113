import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConduiteService} from '../../../../../core/apps/conduite.service';
import {Branchie} from '../../../../../models/branchie';

@Component({
  selector: 'kt-add-branchie',
  templateUrl: './add-branchie.component.html',
  styleUrls: ['./add-branchie.component.scss']
})
export class AddBranchieComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;
	typeoperation;
	selectedValue: string ="";
	constructor(
		public dialogRef: MatDialogRef<AddBranchieComponent>,
		private formBuilder: FormBuilder,
		private TripService: ConduiteService,
	@Inject(MAT_DIALOG_DATA) public data: any,
) { }

	ngOnInit() {
		this.initForm();
	}

	onNoClick() {
		this.dialogRef.close();
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			libelle: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const libelle = this.tripForm.get("libelle").value;
		const newAgriculteur = new Branchie(libelle);
		this.TripService.saveBranchie(newAgriculteur).then( res => {
		});

	}
	onUpdate(id) {
		const libelle = this.tripForm.get("libelle").value;

		let a = {
			id: id,
			libelle: libelle,
		};
		this.TripService.updateBranchie(a).then( res => {
		});

	}
	ondelete(id) {

		this.TripService.deleteBranchie(id);
		this.dialogRef.close();

	}

}
