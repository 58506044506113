import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConduiteService} from '../../../../../core/apps/conduite.service';
import {Vessienatatoire} from '../../../../../models/vessienatatoire';

@Component({
  selector: 'kt-add-vessienatatoire',
  templateUrl: './add-vessienatatoire.component.html',
  styleUrls: ['./add-vessienatatoire.component.scss']
})
export class AddVessienatatoireComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;
	typeoperation;
	selectedValue: string ="";
	constructor(
		public dialogRef: MatDialogRef<AddVessienatatoireComponent>,
		private formBuilder: FormBuilder,
		private TripService: ConduiteService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { }

	ngOnInit() {
		this.initForm();
	}

	onNoClick() {
		this.dialogRef.close();
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			libelle: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const libelle = this.tripForm.get("libelle").value;
		const newAgriculteur = new Vessienatatoire(libelle);
		this.TripService.saveVessienatatoire(newAgriculteur).then( res => {
		});

	}
	onUpdate(id) {
		const libelle = this.tripForm.get("libelle").value;

		let a = {
			id: id,
			libelle: libelle,
		};
		this.TripService.updateVessienatatoire(a).then( res => {
		});

	}
	ondelete(id) {

		this.TripService.deleteVessienatatoire(id);
		this.dialogRef.close();

	}

}
