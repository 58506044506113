import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ConduiteService} from '../../../../core/apps/conduite.service';
import {Subject} from 'rxjs';
import {AddEcloseriesComponent} from './add-ecloseries/add-ecloseries.component';
import {Ecloserie} from '../../../../models/ecloserie';
@Component({
  selector: 'kt-ecloseries',
  templateUrl: './ecloseries.component.html',
  styleUrls: ['./ecloseries.component.scss']
})
export class EcloseriesComponent implements OnInit, OnDestroy {
	displayedColumns = ["select", "ID","Libelle","actions"];
	selection = new SelectionModel<Ecloserie>(false, []);
	annonces: Ecloserie;
	candidatTab  = [];
	/**
	 *
	 * @param router: Router
	 * @param layoutUtilsService: LayoutUtilsService
	 */
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		private TripService: ConduiteService,
		public dialog: MatDialog
	) {}
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<Ecloserie> = new MatTableDataSource([]);

	ngOnInit() {
		this.dataSource = new MatTableDataSource([]);
		let sub=this.TripService.getListEcloseries()
			.subscribe((actions) => {
				this.candidatTab=[];
				return actions.map((a) => {
					let data = a.payload.doc.data() as Ecloserie;
					data.id = a.payload.doc.id;
					//let annonceToString = null;
					let annonceToString = JSON.stringify(
						Object.assign({}, data)
					);
					let stringToJsonObject = JSON.parse(annonceToString);
					this.annonces = stringToJsonObject;
					this.candidatTab.push(this.annonces);
					this.dataSource.data = this.candidatTab;
				});
			});
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}
	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
	}
	openDialogParcelle(conduite: any) {
		let dialogRef = this.dialog.open(AddEcloseriesComponent, {
			width: "600px",
			data: { conduite, list: "add" },
		});
		dialogRef.afterClosed().subscribe((result) => {

		});

	}
	modifierDialog(conduite: any) {
		let dialogRef = this.dialog.open(AddEcloseriesComponent, {
			width: "600px",
			data: {
				id: conduite.id,
				libelle: conduite.libelle,
				list: "update"
			},
		});
		dialogRef.afterClosed().subscribe((result) => {

		});

	}
	deleteDialog(conduite: any) {
		let dialogRef = this.dialog.open(AddEcloseriesComponent, {
			width: "600px",
			data: {
				id: conduite.id,
				list: "delete"
			},
		});
		dialogRef.afterClosed().subscribe((result) => {

		});

	}
	ngOnDestroy() {

	}
}
