import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConduiteService} from '../../../../../core/apps/conduite.service';
import {Entreprise} from '../../../../../models/entreprise';

@Component({
  selector: 'kt-add-entreprise',
  templateUrl: './add-entreprise.component.html',
  styleUrls: ['./add-entreprise.component.scss']
})
export class AddEntrepriseComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;
	typeoperation;
	selectedValue: string ="";
	constructor(
		public dialogRef: MatDialogRef<AddEntrepriseComponent>,
		private formBuilder: FormBuilder,
		private TripService: ConduiteService,
	@Inject(MAT_DIALOG_DATA) public data: any,
) { }

	ngOnInit() {
		this.initForm();
	}

	onNoClick() {
		this.dialogRef.close();
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			libelle: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const libelle = this.tripForm.get("libelle").value;

		const newAgriculteur = new Entreprise(libelle);
		//newAgriculteur.libelle = libelle;

		//newAgriculteur.dateCreation = new Date();
		this.TripService.saveEntreprise(newAgriculteur).then( res => {
		});

	}
	onUpdate(id) {
		const libelle = this.tripForm.get("libelle").value;

		let a = {
			id: id,
			libelle: libelle,
		};
		//newAgriculteur.libelle = libelle;

		//newAgriculteur.dateCreation = new Date();
		this.TripService.updateEntreprise(a).then( res => {
		});

	}
	ondelete(id) {

		this.TripService.deleteEntreprise(id);
		this.dialogRef.close();

	}
}
