export class Examen {
	id: string;
	dateExamen: Date;
	entreprise: string;
	numCage: string;
	espece: string;
	ecloserie: string;
	tempEau: string;
	dateRecepetion: Date;
	pm: string;
	mortaliteparjours: string;
	nbrJoursMortalite: string;
	totalMortalite: string;
	incident: string;

	embonpointobservation: string;
	embonpointobservationinput: string;
	embonpointfrequence: string;
	embonpointseverite: string;
	//embonpoint: {observation:string,frequence:string,severite:string};

	//revetement: {observation:string,frequence:string,severite:string};
	revetementobservation: string;
	revetementobservationinput: string;
	revetementfrequence: string;
	revetementseverite: string;

	//nageoires: {observation:string,frequence:string,severite:string};
	nageoiresobservation: string;
	nageoiresobservationinput: string;
	nageoiresfrequence: string;
	nageoiresseverite: string;

	//opercule: {observation:string,frequence:string,severite:string};
	operculeobservation: string;
	operculeobservationinput: string;
	operculefrequence: string;
	operculeseverite: string;

	//bouche: {observation:string,frequence:string,severite:string};
	boucheobservation: string;
	boucheobservationinput: string;
	bouchefrequence: string;
	boucheseverite: string;

	//estomac: {observation:string,frequence:string,severite:string};
	estomacobservation: string;
	estomacobservationinput: string;
	estomacfrequence: string;
	estomacseverite: string;

	//intestin: {observation:string,frequence:string,severite:string};
	intestinobservation: string;
	intestinobservationinput: string;
	intestinfrequence: string;
	intestinseverite: string;

	//branchie: {observation:string,frequence:string,severite:string};
	branchieobservation: string;
	branchieobservationinput: string;
	branchiefrequence: string;
	branchieseverite: string;

	//coeur: {observation:string,frequence:string,severite:string};
	coeurobservation: string;
	coeurobservationinput: string;
	coeurfrequence: string;
	coeurseverite: string;

	//tete: {observation:string,frequence:string,severite:string};
	teteobservation: string;
	teteobservationinput: string;
	tetefrequence: string;
	teteseverite: string;

	//yeux: {observation:string,frequence:string,severite:string};
	yeuxobservation: string;
	yeuxobservationinput: string;
	yeuxfrequence: string;
	yeuxseverite: string;

	//cerveau: {observation:string,frequence:string,severite:string};
	cerveauobservation: string;
	cerveauobservationinput: string;
	cerveaufrequence: string;
	cerveauseverite: string;

	//rate: {observation:string,frequence:string,severite:string};
	rateobservation: string;
	rateobservationinput: string;
	ratefrequence: string;
	rateseverite: string;

	//foie: {observation:string,frequence:string,severite:string};
	foieobservation: string;
	foieobservationinput: string;
	foiefrequence: string;
	foieseverite: string;

	//vesiculeBiliaire: {observation:string,frequence:string,severite:string};
	vesiculeBiliaireobservation: string;
	vesiculeBiliaireobservationinput: string;
	vesiculeBiliairefrequence: string;
	vesiculeBiliaireseverite: string;

	//rein: {observation:string,frequence:string,severite:string};
	reinobservation: string;
	reinobservationinput: string;
	reinfrequence: string;
	reinseverite: string;

	//vessienatatoire: {observation:string,frequence:string,severite:string};
	vessienatatoireobservation: string;
	vessienatatoireobservationinput: string;
	vessienatatoirefrequence: string;
	vessienatatoireseverite: string;

	//autres: {observation:string,frequence:string,severite:string};
	autresobservation: string;
	autresobservationinput: string;
	autresfrequence: string;
	autresseverite: string;

	//rechercheparasitaire: {observation:string,forme:string};
	rechercheparasitaireobservation: string;
	rechercheparasitaireforme: string;
	parasitaire:string;

	//recherchebacteriologique: {observation:string,forme:string};
	recherchebacteriologiqueobservation: string;
	recherchebacteriologiqueforme: string;
    bacteriologique:string;

	//diagnostic: {diagnostictexte:string,observation:string,forme:string};
	diagnosticdiagnostictexte: string;
	diagnosticobservation: string;
	diagnosticforme: string;

	//conduite: {conduitetexte:string,observation:string,molecule:string,durretraitement:string};
	conduiteconduitetexte: string;
	conduiteobservation: string;
	conduitemolecule: string;
	conduitedurretraitement: string;

    imageurl:string;
    imageurlconvert:any;
    imageurl1:string;
	imageurlconvert1:any;
    imageurl2:string;
	imageurlconvert2:any;
    imageurl3:string;
	imageurlconvert3:any;
    imageurl4:string;
	imageurlconvert4:any;
    image:[string];


	constructor() {	}
}
