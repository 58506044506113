import { Examen } from "./../../models/examen";
import { Injectable } from "@angular/core";
import {
	AngularFirestore,
} from "@angular/fire/firestore";
import {  Observable, Subject } from "rxjs";
import { Conduite } from "../../models/conduite";
import { Diagnostic } from "../../models/diagnostic";
import { Autres } from "../../models/autres";
import { Parasitaire } from "../../models/parasitaire";
import { Bacteriologique } from "../../models/bacteriologique";
import { DatePipe } from "@angular/common";
import {Entreprise} from '../../models/entreprise';
import {Espece} from '../../models/espece';
import {Ecloserie} from '../../models/ecloserie';
import {Embonpoint} from '../../models/embonpoint';
import {Revetementcutane} from '../../models/revetementcutane';
import {Nageoires} from '../../models/nageoires';
import {Opercule} from '../../models/opercule';
import {Bouche} from '../../models/bouche';
import {Estomac} from '../../models/estomac';
import { Intestin } from '../../models/intestin';
import {Branchie} from '../../models/branchie';
import { Coeur } from '../../models/coeur';
import { Tete } from '../../models/tete';
import { Yeux } from '../../models/yeux';
import { Cerveau } from '../../models/cerveau';
import {Rate} from '../../models/rate';
import { Foie } from '../../models/foie';
import { Vesiculebiliaire } from '../../models/vesiculebiliaire';
import {Rein} from '../../models/rein';
import {Vessienatatoire} from '../../models/vessienatatoire';

@Injectable({
  providedIn: 'root'
})
export class ConduiteService {

  conduite: Conduite[] = [];
  parasitaire: Parasitaire[] = [];
  bacteriologique: Bacteriologique[] = [];
  diagnostic: Diagnostic[] = [];
  examen: Examen[] = [];
  autres: Autres[] = [];
  entreprises: Entreprise[] = [];
  id: string = "";
  myDate = new Date();
	suggestionSubject = new Subject<Conduite[]>();
	parasitaireSubject = new Subject<Parasitaire[]>();
	bacteriologiqueSubject = new Subject<Bacteriologique[]>();
	suggestionSubjectdiagnostic = new Subject<Diagnostic[]>();
	suggestionSubjectExamen = new Subject<Examen[]>();
	suggestionSubjectAutres = new Subject<Autres[]>();
	suggestionSubjectEntreprises = new Subject<Entreprise[]>();
	constructor(private firestore: AngularFirestore,
		private datePipe: DatePipe
	) {}

  emitConduite() {
		this.suggestionSubject.next(this.conduite);
	}
  emitparasitaire() {
		this.parasitaireSubject.next(this.parasitaire);
	}
  emitbacteriologique() {
		this.bacteriologiqueSubject.next(this.bacteriologique);
	}
  emitDiagnostic() {
		this.suggestionSubjectdiagnostic.next(this.diagnostic);
	}
  emitexamen() {
		this.suggestionSubjectExamen.next(this.examen);
	}
  emitautres() {
		this.suggestionSubjectAutres.next(this.autres);
	}
	emitentreprise() {
		this.suggestionSubjectEntreprises.next(this.entreprises);
	}

	getListparasitaire() {
		return this.firestore
			.collection("Parasitaire")
			.snapshotChanges();
	}
	getListbacteriologique() {
		return this.firestore
			.collection("Bacteriologique")
			.snapshotChanges();
	}
	getListConduite() {
		return this.firestore
			.collection("Conduites")
			.snapshotChanges();
	}
	getListEntreprise() {
		return this.firestore
			.collection("Entreprises")
			.snapshotChanges();
	}
	getListEcloseries() {
		return this.firestore
			.collection("Ecloseries")
			.snapshotChanges();
	}
	getListIncidents() {
		return this.firestore
			.collection("Incidents")
			.snapshotChanges();
	}

	getListEspeces() {
		return this.firestore
			.collection("Especes")
			.snapshotChanges();
	}

	getListEmbonpoints() {
		return this.firestore
			.collection("Embonpoints")
			.snapshotChanges();
	}

	getListdiagnostic() {
		return this.firestore
			.collection("diagnostics")
			.snapshotChanges();
	}
	getListexamen() {
		return this.firestore
			.collection("examens", ref =>
			ref.orderBy('dateExamen','desc'))
			.snapshotChanges();
	}
	getListautres() {
		return this.firestore
			.collection("autres")
			.snapshotChanges();
	}
	updateBacteriologique(data) {
		this.id = data.id;
		return this.firestore.collection("Bacteriologique").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateParasitaire(data) {
		this.id = data.id;
		return this.firestore.collection("Parasitaire").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateConduite(data) {
		this.id = data.id;
		return this.firestore.collection("Conduites").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateEntreprise(data) {
		this.id = data.id;
		return this.firestore.collection("Entreprises").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateIncident(data) {
		this.id = data.id;
		return this.firestore.collection("Incidents").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateEcloseries(data) {
		this.id = data.id;
		return this.firestore.collection("Ecloseries").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateEspece(data) {
		this.id = data.id;
		return this.firestore.collection("Especes").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateEmbonpoint(data) {
		this.id = data.id;
		return this.firestore.collection("Embonpoints").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updatediagnostic(data) {
		this.id = data.id;
		return this.firestore.collection("diagnostics").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateExamen(data) {
		console.log(data.id);

		this.id = data.id;
		return this.firestore.collection("examens").doc(this.id).set({
					dateModification: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),
					//image: data.image,
					bacteriologique: data.bacteriologique,
					parasitaire: data.parasitaire,
					dateExamen: data.dateExamen,
					entreprise: data.entreprise,
					numCage: data.numCage,
					espece: data.espece,
					ecloserie: data.ecloserie,
					tempEau: data.tempEau,
					dateRecepetion: data.dateRecepetion,
					pm: data.pm,
					mortaliteparjours: data.mortaliteparjours,
					nbrJoursMortalite: data.nbrJoursMortalite,
					totalMortalite: data.totalMortalite,
					incident: data.incident,


					embonpointobservation : data.embonpointobservation,
					embonpointobservationinput : data.embonpointobservationinput,
   					embonpointfrequence :data.embonpointfrequence,
    				embonpointseverite : data.embonpointseverite,

					//revetement: revetement,
					revetementobservation : data.revetementobservation,
					revetementobservationinput : data.revetementobservationinput,
					revetementfrequence : data.revetementfrequence,
					revetementseverite : data.revetementseverite,

					//nageoires: data.nageoires,
					nageoiresobservation :data. nageoiresobservation,
					nageoiresobservationinput :data.nageoiresobservationinput,
					nageoiresfrequence : data.nageoiresfrequence,
					nageoiresseverite : data.nageoiresseverite,

					//opercule: data.opercule,
					operculeobservation : data.operculeobservation,
					operculeobservationinput : data.operculeobservationinput,
					operculefrequence : data.operculefrequence,
					operculeseverite : data.operculeseverite,

					//bouche: data.bouche,
					boucheobservation : data.boucheobservation,
					boucheobservationinput : data.boucheobservationinput,
					bouchefrequence : data.bouchefrequence,
					boucheseverite : data.boucheseverite,

					//estomac: data.estomac,
					estomacobservation : data.estomacobservation,
					estomacobservationinput : data.estomacobservationinput,
					estomacfrequence : data.estomacfrequence,
					estomacseverite : data.estomacseverite,

					//intestin: data.intestin,
					intestinobservation :data.intestinobservation,
					intestinobservationinput :data.intestinobservationinput,
					intestinfrequence : data.intestinfrequence,
					intestinseverite : data.intestinseverite,

					//branchie: data.branchie,
					branchieobservation : data.branchieobservation,
					branchieobservationinput : data.branchieobservationinput,
					branchiefrequence : data.branchiefrequence,
					branchieseverite : data.branchieseverite,

					//coeur: data.coeur,
					coeurobservation : data.coeurobservation,
					coeurobservationinput : data.coeurobservationinput,
					coeurfrequence : data.coeurfrequence,
					coeurseverite : data.coeurseverite,

					//tete: data.tete,
					teteobservation : data.teteobservation,
					teteobservationinput : data.teteobservationinput,
					tetefrequence : data.tetefrequence,
					teteseverite : data.teteseverite,

					//yeux: data.yeux,
					yeuxobservation : data.yeuxobservation,
					yeuxobservationinput : data.yeuxobservationinput,
					yeuxfrequence : data.yeuxfrequence,
					yeuxseverite : data.yeuxseverite,

					//cerveau: data.cerveau,
					cerveauobservation : data.cerveauobservation,
					cerveauobservationinput : data.cerveauobservationinput,
					cerveaufrequence : data.cerveaufrequence,
					cerveauseverite : data.cerveauseverite,

					//rate: data.rate,
					rateobservation : data.rateobservation,
					rateobservationinput : data.rateobservationinput,
					ratefrequence : data.ratefrequence,
					rateseverite : data.rateseverite,

					//foie: data.foie,
					foieobservation : data.foieobservation,
					foieobservationinput : data.foieobservationinput,
					foiefrequence : data.foiefrequence,
					foieseverite : data.foieseverite,

					//vesiculeBiliaire: data.vesiculeBiliaire,
					vesiculeBiliaireobservation : data.vesiculeBiliaireobservation,
					vesiculeBiliaireobservationinput : data.vesiculeBiliaireobservationinput,
					vesiculeBiliairefrequence : data.vesiculeBiliairefrequence,
					vesiculeBiliaireseverite : data.vesiculeBiliaireseverite,

					//rein: data.rein,
					reinobservation :data.reinobservation,
					reinobservationinput :data.reinobservationinput,
					reinfrequence : data.reinfrequence,
					reinseverite : data.reinseverite,

					//vessienatatoire: data.vessienatatoire,
					rechercheparasitaireobservation : data.rechercheparasitaireobservation,
					rechercheparasitaireforme : data.rechercheparasitaireforme,

					recherchebacteriologiqueobservation : data.recherchebacteriologiqueobservation,
					recherchebacteriologiqueforme : data.recherchebacteriologiqueforme,

					vessienatatoireobservation : data.vessienatatoireobservation,
					vessienatatoireobservationinput : data.vessienatatoireobservationinput,
					vessienatatoirefrequence : data.vessienatatoirefrequence,
					vessienatatoireseverite : data.vessienatatoireseverite,

					//autres: data.autres,
					autresobservation : data.autresobservation,
					autresobservationinput : data.autresobservationinput,
					autresfrequence : data.autresfrequence,
					autresseverite : data.autresseverite,

					//diagnostic: data.diagnostic,
					diagnosticdiagnostictexte : data.diagnosticdiagnostictexte,
					diagnosticobservation : data.diagnosticobservation,
					diagnosticforme : data.diagnosticforme,

					//conduite: data.conduite,
					conduiteconduitetexte : data.conduiteconduitetexte,
					conduiteobservation : data.conduiteobservation ,
					conduitemolecule : data.conduitemolecule ,
					conduitedurretraitement : data.conduitedurretraitement,
					imageurl : data.imageurl===""?"":data.imageurl,
					imageurlconvert : data.imageurlconvert===""?"":data.imageurlconvert,
					imageurl1 : data.imageurl1===""?"":data.imageurl1,
					imageurlconvert1 : data.imageurlconvert1===""?"":data.imageurlconvert1,
					imageurl2 : data.imageurl2===""?"":data.imageurl2,
					imageurlconvert2 : data.imageurlconvert2===""?"":data.imageurlconvert2,
					imageurl3 : data.imageurl3===""?"":data.imageurl3,
					imageurlconvert3 : data.imageurlconvert3===""?"":data.imageurlconvert3,
					imageurl4 : data.imageurl4===""?"":data.imageurl4,
					imageurlconvert4 : data.imageurlconvert4===""?"":data.imageurlconvert4,

		});
	}
	getexamensbyid(id: string): Observable<any> {
		//this.id = id;
		return this.firestore
			.collection("examens")
      .doc(id)
      .valueChanges();
	}
	updateAutres(data) {
		this.id = data.id;
		return this.firestore.collection("autres").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	saveparasitaire(conduite: Parasitaire): Promise<Parasitaire> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Parasitaire/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	savebacteriologique(conduite: Bacteriologique): Promise<Bacteriologique> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Bacteriologique/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveConduite(conduite: Conduite): Promise<Conduite> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Conduites/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveEntreprise(entreprise: Entreprise): Promise<Entreprise> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Entreprises/")
				.add({
					libelle: entreprise.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveIncident(entreprise: Entreprise): Promise<Entreprise> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Incidents/")
				.add({
					libelle: entreprise.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveEcloseries(ecloserie: Ecloserie): Promise<Entreprise> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Ecloseries/")
				.add({
					libelle: ecloserie.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveEspece(espece: Espece): Promise<Espece> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Especes/")
				.add({
					libelle: espece.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}

	saveEmbonpoint(embonpoint: Embonpoint): Promise<Embonpoint> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Embonpoints/")
				.add({
					libelle: embonpoint.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}

	savediagnostic(conduite: Diagnostic): Promise<Diagnostic> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("diagnostics/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveAutres(conduite: Autres): Promise<Autres> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("autres/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getBase64ImageFromURL(url) {
		return new Promise((resolve, reject) => {
		  var img = new Image();
		  img.setAttribute("crossOrigin", "anonymous");

		  img.onload = () => {
			var canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);

			var dataURL = canvas.toDataURL("image/png");

			resolve(dataURL);
		  };

		  img.onerror = error => {
			reject(error);
		  };

		  img.src = url;
		});
	  }
	  url2;
	   //method to retrieve download url
	   public async getUrl(snap: firebase.storage.UploadTaskSnapshot) {
	//let url;
   let  url = await snap.ref.getDownloadURL();
   this.url2 = url;
   console.log(url);
   console.log(this.url2);

   return this.url2;
  }
	saveExamen(exam: Examen): Promise<Examen> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("examens/")
				.add({
					//image: exam.image,
					//imageurl: exam.imageurl,
					bacteriologique: exam.bacteriologique,
					parasitaire: exam.parasitaire,
					dateExamen: exam.dateExamen,
					entreprise: exam.entreprise,
					numCage: exam.numCage,
					espece: exam.espece,
					ecloserie: exam.ecloserie,
					tempEau: exam.tempEau,
					dateRecepetion: exam.dateRecepetion,
					pm: exam.pm,
					mortaliteparjours: exam.mortaliteparjours,
					nbrJoursMortalite: exam.nbrJoursMortalite,
					totalMortalite: exam.totalMortalite,
					incident: exam.incident,

					embonpointobservation : exam.embonpointobservation,
					embonpointobservationinput : exam.embonpointobservationinput,
   					embonpointfrequence : exam.embonpointfrequence,
    				embonpointseverite : exam.embonpointseverite,

					//revetement: revetement,
					revetementobservation : exam.revetementobservation,
					revetementobservationinput : exam.revetementobservationinput,
					revetementfrequence : exam.revetementfrequence,
					revetementseverite : exam.revetementseverite,

					//nageoires: exam.nageoires,
					nageoiresobservation : exam.nageoiresobservation,
					nageoiresobservationinput : exam.nageoiresobservationinput,
					nageoiresfrequence : exam.nageoiresfrequence,
					nageoiresseverite : exam.nageoiresseverite,

					//opercule: exam.opercule,
					operculeobservation : exam.operculeobservation,
					operculeobservationinput : exam.operculeobservationinput,
					operculefrequence : exam.operculefrequence,
					operculeseverite : exam.operculeseverite,

					//bouche: exam.boucheobservation,
					boucheobservation : exam.boucheobservation,
					boucheobservationinput : exam.boucheobservationinput,
					bouchefrequence : exam.bouchefrequence,
					boucheseverite : exam.boucheseverite,

					//estomac: exam.estomac,
					estomacobservation : exam.estomacobservation,
					estomacobservationinput : exam.estomacobservationinput,
					estomacfrequence : exam.estomacfrequence,
					estomacseverite : exam.estomacseverite,

					//intestin: exam.intestin,
					intestinobservation : exam.intestinobservation,
					intestinobservationinput : exam.intestinobservationinput,
					intestinfrequence : exam.intestinfrequence,
					intestinseverite : exam.intestinseverite,

					//branchie: exam.branchie,
					branchieobservation : exam.branchieobservation,
					branchieobservationinput : exam.branchieobservationinput,
					branchiefrequence : exam.branchiefrequence,
					branchieseverite : exam.branchieseverite,

					//coeur: exam.coeur,
					coeurobservation : exam.coeurobservation,
					coeurobservationinput : exam.coeurobservationinput,
					coeurfrequence : exam.coeurfrequence,
					coeurseverite : exam.coeurseverite,

					//tete: exam.tete,
					teteobservation : exam.teteobservation,
					teteobservationinput : exam.teteobservationinput,
					tetefrequence : exam.tetefrequence,
					teteseverite : exam.teteseverite,

					//yeux: exam.yeux,
					yeuxobservation : exam.yeuxobservation,
					yeuxobservationinput : exam.yeuxobservationinput,
					yeuxfrequence : exam.yeuxfrequence,
					yeuxseverite : exam.yeuxseverite,

					//cerveau: exam.cerveau,
					cerveauobservation : exam.cerveauobservation,
					cerveauobservationinput : exam.cerveauobservationinput,
					cerveaufrequence : exam.cerveaufrequence,
					cerveauseverite : exam.cerveauseverite,

					//rate: exam.rate,
					rateobservation : exam.rateobservation,
					rateobservationinput : exam.rateobservationinput,
					ratefrequence : exam.ratefrequence,
					rateseverite : exam.rateseverite,

					//foie: exam.foie,
					foieobservation : exam.foieobservation,
					foieobservationinput : exam.foieobservationinput,
					foiefrequence : exam.foiefrequence,
					foieseverite : exam.foieseverite,

					//vesiculeBiliaire: exam.vesiculeBiliaire,
					vesiculeBiliaireobservation : exam.vesiculeBiliaireobservation,
					vesiculeBiliaireobservationinput : exam.vesiculeBiliaireobservationinput,
					vesiculeBiliairefrequence : exam.vesiculeBiliairefrequence,
					vesiculeBiliaireseverite : exam.vesiculeBiliaireseverite,

					//rein: exam.rein,
					reinobservation : exam.reinobservation,
					reinobservationinput : exam.reinobservationinput,
					reinfrequence : exam.reinfrequence,
					reinseverite : exam.reinseverite,

					//vessienatatoire: exam.vessienatatoire,
					rechercheparasitaireobservation : exam.rechercheparasitaireobservation,
					rechercheparasitaireforme : exam.rechercheparasitaireforme,

					recherchebacteriologiqueobservation : exam.recherchebacteriologiqueobservation,
					recherchebacteriologiqueforme : exam.recherchebacteriologiqueforme,

					vessienatatoireobservation : exam.vessienatatoireobservation,
					vessienatatoireobservationinput : exam.vessienatatoireobservationinput,
					vessienatatoirefrequence : exam.vessienatatoirefrequence,
					vessienatatoireseverite : exam.vessienatatoireseverite,

					//autres: exam.autres,
					autresobservation : exam.autresobservation,
					autresobservationinput : exam.autresobservationinput,
					autresfrequence : exam.autresfrequence,
					autresseverite : exam.autresseverite,

					//diagnostic: exam.diagnostic,
					diagnosticdiagnostictexte : exam.diagnosticdiagnostictexte,
					diagnosticobservation : exam.diagnosticobservation,
					diagnosticforme : exam.diagnosticforme,

					//conduite: exam.conduite,
					conduiteconduitetexte : exam.conduiteconduitetexte,
					conduiteobservation : exam.conduiteobservation,
					conduitemolecule : exam.conduitemolecule,
					conduitedurretraitement : exam.conduitedurretraitement,
					imageurl : exam.imageurl===""?"":exam.imageurl,
					imageurlconvert : exam.imageurlconvert===""?"":exam.imageurlconvert,
					imageurl1 : exam.imageurl1===""?"":exam.imageurl1,
					imageurlconvert1 : exam.imageurlconvert1===""?"":exam.imageurlconvert1,
					imageurl2 : exam.imageurl2===""?"":exam.imageurl2,
					imageurlconvert2 : exam.imageurlconvert2===""?"":exam.imageurlconvert2,
					imageurl3 : exam.imageurl3===""?"":exam.imageurl3,
					imageurlconvert3 : exam.imageurlconvert3===""?"":exam.imageurlconvert3,
					imageurl4 : exam.imageurl4===""?"":exam.imageurl4,
					imageurlconvert4 : exam.imageurlconvert4===""?"":exam.imageurlconvert4,
					//imageurl: exam.imageurl,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	deleteconduite(id: string) {
		this.firestore.doc("Conduites/" + id).delete();
	}
	deleteEntreprise(id: string) {
		this.firestore.doc("Entreprises/" + id).delete();
	}
	deleteIncident(id: string) {
		this.firestore.doc("Incidents/" + id).delete();
	}
	deleteEcloseries(id: string) {
		this.firestore.doc("Ecloseries/" + id).delete();
	}
	deleteEspece(id: string) {
		this.firestore.doc("Especes/" + id).delete();
	}
	deleteEmbonpoint(id: string) {
		this.firestore.doc("Embonpoints/" + id).delete();
	}
	deleteDiagnostic(id: string) {
		this.firestore.doc("diagnostics/" + id).delete();
	}
  deleteExamen(id: string) {
		this.firestore.doc("examens/" + id).delete();
	}
  deleteautres(id: string) {
		this.firestore.doc("autres/" + id).delete();
	}
  deleteBacteriologique(id: string) {
		this.firestore.doc("Bacteriologique/" + id).delete();
	}
  deleteParasitaire(id: string) {
		this.firestore.doc("Parasitaire/" + id).delete();
	}

  deleteRevetementcutane(id: string) {
		this.firestore.doc("Revetementcutane/" + id).delete();
	}
	saveRevetementcutane(conduite: Revetementcutane): Promise<Revetementcutane> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Revetementcutane/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListRevetementcutane() {
		return this.firestore
			.collection("Revetementcutane")
			.snapshotChanges();
	}
	updateRevetementcutane(data) {
		this.id = data.id;
		return this.firestore.collection("Revetementcutane").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteNageoires(id: string) {
		this.firestore.doc("Nageoires/" + id).delete();
	}
	saveNageoires(conduite: Nageoires): Promise<Nageoires> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Nageoires/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListNageoires() {
		return this.firestore
			.collection("Nageoires")
			.snapshotChanges();
	}
	updateNageoires(data) {
		this.id = data.id;
		return this.firestore.collection("Nageoires").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteOpercule(id: string) {
		this.firestore.doc("Opercule/" + id).delete();
	}
	saveOpercule(conduite: Opercule): Promise<Opercule> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Opercule/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListOpercule() {
		return this.firestore
			.collection("Opercule")
			.snapshotChanges();
	}
	updateOpercule(data) {
		this.id = data.id;
		return this.firestore.collection("Opercule").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteBouche(id: string) {
		this.firestore.doc("Bouche/" + id).delete();
	}
	saveBouche(conduite: Bouche): Promise<Bouche> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Bouche/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListBouche() {
		return this.firestore
			.collection("Bouche")
			.snapshotChanges();
	}
	updateBouche(data) {
		this.id = data.id;
		return this.firestore.collection("Bouche").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteEstomac(id: string) {
		this.firestore.doc("Estomac/" + id).delete();
	}
	saveEstomac(conduite: Estomac): Promise<Estomac> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Estomac/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListEstomac() {
		return this.firestore
			.collection("Estomac")
			.snapshotChanges();
	}
	updateEstomac(data) {
		this.id = data.id;
		return this.firestore.collection("Estomac").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteIntestin(id: string) {
		this.firestore.doc("Intestin/" + id).delete();
	}
	saveIntestin(conduite: Intestin): Promise<Intestin> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Intestin/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListIntestin() {
		return this.firestore
			.collection("Intestin")
			.snapshotChanges();
	}
	updateIntestin(data) {
		this.id = data.id;
		return this.firestore.collection("Intestin").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteBranchie(id: string) {
		this.firestore.doc("Branchie/" + id).delete();
	}
	saveBranchie(conduite: Branchie): Promise<Branchie> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Branchie/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListBranchie() {
		return this.firestore
			.collection("Branchie")
			.snapshotChanges();
	}
	updateBranchie(data) {
		this.id = data.id;
		return this.firestore.collection("Branchie").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteCoeur(id: string) {
		this.firestore.doc("Coeur/" + id).delete();
	}
	saveCoeur(conduite: Coeur): Promise<Coeur> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Coeur/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListCoeur() {
		return this.firestore
			.collection("Coeur")
			.snapshotChanges();
	}
	updateCoeur(data) {
		this.id = data.id;
		return this.firestore.collection("Coeur").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteTete(id: string) {
		this.firestore.doc("Tete/" + id).delete();
	}
	saveTete(conduite: Tete): Promise<Tete> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Tete/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListTete() {
		return this.firestore
			.collection("Tete")
			.snapshotChanges();
	}
	updateTete(data) {
		this.id = data.id;
		return this.firestore.collection("Tete").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteYeux(id: string) {
		this.firestore.doc("Yeux/" + id).delete();
	}
	saveYeux(conduite: Yeux): Promise<Yeux> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Yeux/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListYeux() {
		return this.firestore
			.collection("Yeux")
			.snapshotChanges();
	}
	updateYeux(data) {
		this.id = data.id;
		return this.firestore.collection("Yeux").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteCerveau(id: string) {
		this.firestore.doc("Cerveau/" + id).delete();
	}
	saveCerveau(conduite: Cerveau): Promise<Cerveau> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Cerveau/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListCerveau() {
		return this.firestore
			.collection("Cerveau")
			.snapshotChanges();
	}
	updateCerveau(data) {
		this.id = data.id;
		return this.firestore.collection("Cerveau").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteRate(id: string) {
		this.firestore.doc("Rate/" + id).delete();
	}
	saveRate(conduite: Rate): Promise<Rate> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Rate/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListRate() {
		return this.firestore
			.collection("Rate")
			.snapshotChanges();
	}
	updateRate(data) {
		this.id = data.id;
		return this.firestore.collection("Rate").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteFoie(id: string) {
		this.firestore.doc("Foie/" + id).delete();
	}
	saveFoie(conduite: Foie): Promise<Foie> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Foie/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListFoie() {
		return this.firestore
			.collection("Foie")
			.snapshotChanges();
	}
	updateFoie(data) {
		this.id = data.id;
		return this.firestore.collection("Foie").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteVesiculebiliaire(id: string) {
		this.firestore.doc("Vesiculebiliaire/" + id).delete();
	}
	saveVesiculebiliaire(conduite: Vesiculebiliaire): Promise<Vesiculebiliaire> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Vesiculebiliaire/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListVesiculebiliaire() {
		return this.firestore
			.collection("Vesiculebiliaire")
			.snapshotChanges();
	}
	updateVesiculebiliaire(data) {
		this.id = data.id;
		return this.firestore.collection("Vesiculebiliaire").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteRein(id: string) {
		this.firestore.doc("Rein/" + id).delete();
	}
	saveRein(conduite: Rein): Promise<Rein> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Rein/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListRein() {
		return this.firestore
			.collection("Rein")
			.snapshotChanges();
	}
	updateRein(data) {
		this.id = data.id;
		return this.firestore.collection("Rein").doc(this.id).set({
			libelle: data.libelle,
		});
	}

  deleteVessienatatoire(id: string) {
		this.firestore.doc("Vessienatatoire/" + id).delete();
	}
	saveVessienatatoire(conduite: Vessienatatoire): Promise<Vessienatatoire> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Vessienatatoire/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getListVessienatatoire() {
		return this.firestore
			.collection("Vessienatatoire")
			.snapshotChanges();
	}
	updateVessienatatoire(data) {
		this.id = data.id;
		return this.firestore.collection("Vessienatatoire").doc(this.id).set({
			libelle: data.libelle,
		});
	}


}
